
  .edit-location__two-columns {
    display: flex;

    > .edit-location__cell {
      flex: 1;

      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
