
  @use "frontend/styles/mixins/label";

  .form-relative-time {
    padding: 10px 0;
  }

  .frt__label,
  .frt__label--error {
    @include label.label;
  }

  .frt__label--error {
    @include label.label--error;

    display: block;
  }
  .frt__picker-area {
    display: flex;

    align-items: center;

    padding-top: 10px;

    :deep(*) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .frt__amount {
    margin-right: 10px;

    width: 60px;
  }

  .frt__animation-status {
    margin-left: 10px;
  }
