
  @use "shared/styles/colors";

  .now-indicator > div {
    position: absolute;
    color: colors.$color-now-indicator--text;
  }

  .now-indicator__label {
    font-size: 14px;
    left: -54px;
  }

  .now-indicator__line {
    width: calc(100% + 1px);
    border-top: 1px solid;
    margin-left: -1px;
    margin-top: -1px;
    z-index: 4;
  }

  .now-indicator__circle {
    font-size: 10px;
    margin-left: -6px;
    margin-top: -6px;
    z-index: 4;
  }
