
  @use "shared/styles/colors";

  .appointments__wrapper {
    display: flex;
    justify-content: space-between;

    padding: 8px;
    border: 1px solid colors.$color_base-list--separator;
  }

  .appointments--header {
    background-color: colors.$color_patient-search__patient--background;

    > div {
      flex: 1;
    }
  }

  .appointments__info div {
    flex: 1;
  }

  .appointment-box {
    margin: 10px 0;
  }

  .appointment-box__container {
    display: flex;
  }

  .appointment-icon__calendar {
    font-size: 20px;
    padding-right: 10px;
  }

  .appointment__date {
    margin-bottom: 5px;
  }

  .appointment-button__delete {
    background-color: transparent;
    color: colors.$color_main-area--background;
    font-size: 20px;
    padding: 0;
    margin-left: auto;
    align-self: center;

    &:hover {
      background-color: transparent;
      font-size: 30px;
    }
  }
