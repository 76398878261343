
  @use "frontend/styles/mixins/badge";

  .news-entry {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .news-entry__list-item {
    margin: 10px 0;
  }

  .news-entry__title {
    margin-bottom: 5px;

    text-transform: none;

    font-size: 24px;
  }

  .news-entry__date {
    margin-bottom: 12px;

    font-style: italic;
  }

  .news-entry__badge {
    @include badge.base;
  }
