@use "shared/styles/colors";

@mixin entry() {
  padding: 7px 15px; // tb lr
  user-select: none;
}

@mixin entry--hover() {
  background-color: colors.$color_context-menu--hover;
  color: colors.$color_context-menu--hover--text;
}
