
  .main-heading {
    text-align: center;
    font-size: 18px;
  }

  .navigation-button__container {
    padding: 15px 0;
    width: 180px;
    margin: 0 auto; // center horizontally
  }

  .praxis-message {
    margin-top: 20px;
  }
