
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/link";

  .sticky-icon {
    @include link.link;
    text-decoration: none;

    position: fixed;
    bottom: 40px;
    right: 40px;
    font-size: 45px;

    background-color: colors.$color_link--optional-background;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 5px 5px 12px 3px #00000040;
    margin-right: 10px;

    text-align: right;

    float: right;

    &:hover {
      color: colors.$color_link--optional-hover;
    }
  }

  .no-entries {
    text-align: center;
    font-weight: bold;
    font-size: 16px;

    margin: 30px 0;
  }

  .filter-container {
    margin-bottom: 20px;
  }

  .action {
    @include link.link;
    font-size: 12px;

    display: inline-block;

    margin-right: 10px;
    margin-top: 10px;

    user-select: none;
  }
