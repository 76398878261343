
  @use "sass:color";
  @use "shared/styles/colors";
  @use "frontend/styles/features";

  .unit {
    display: flex;

    background-color: colors.$color_calendar__unit--background;

    font-size: 14px;

    overflow: hidden;

    // &:hover,
    &.unit--hover-on {
      outline: 3px solid colors.$color_calendar__unit--hover-on--outline;
    }
  }

  .unit--highlight-background {
    background-color: colors.$color_calendar__unit--highlight-background;
  }

  .unit__icon {
    font-size: 20px;
    color: colors.$color_calendar__unit__icon--text;
  }
  .praxis_icon {
    font-size: 40px;
  }

  .unit--disabled {
    background-color: colors.$color_calendar__unit__disabled--background;
    cursor: not-allowed;
    color: colors.$color_calendar__unit__disabled--text;
  }

  .unit__badge {
    width: 7px;
    margin-right: 8px;
  }

  .unit__content {
    padding: 5px 0;
    flex: 1;

    overflow: hidden;

    &--no-padding {
      padding: 1px 0;
    }
  }
  .unit__icons {
    padding: 5px;
    font-size: 18px;

    &--no-padding {
      padding: 1px 0;
    }
  }

  .unit__icons--stacked {
    i {
      display: block;
    }
  }

  .unit__time {
    display: inline-block;
    width: 36px;

    margin-right: 12px;
  }

  .unit__patient__last-name {
    @if features.$feature_calendar-patient--uppercase {
      text-transform: uppercase;
    }
    font-weight: bold;
  }
  .unit--placeholder {
    background-color: transparent;
  }

  .icon--has-praxisapp {
    font-size: 20px;
    color: colors.$color_calendar__unit__praxisapp-icon--text;
  }
