
  @use "shared/styles/colors";

  .holidays__wrapper {
    margin: 50px 0;
    border: solid 1px colors.$color_base-list--separator;
  }

  .holidays__row {
    display: flex;
    flex-flow: row wrap;
    border-bottom: solid 1px colors.$color_base-list--separator;

    &:last-of-type {
      border: none;
    }
  }

  .holidays--header {
    background-color: colors.$color_holidays-table-header--background;
  }

  .holidays__cell {
    flex: 1;
    padding: 8px;
  }

  .holidays__first-cell {
    width: 80px;
    padding: 8px;
    border-right: solid 1px colors.$color_base-list--separator;
  }

  .holidays-new-year {
    border-top: 2px solid colors.$color_inline-editable__outline;
  }

  .holidays-action {
    margin: 30px 0;
  }
