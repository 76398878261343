
  @use "frontend/styles/mixins/label";

  .edit-attendance {
    margin-bottom: 100px;
  }

  .edit-location__roles-label {
    @include label.label;
  }

  .edit-attendance__columns {
    display: flex;

    margin-bottom: 25px;

    > div {
      min-width: 350px;

      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
