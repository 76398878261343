
  @use "frontend/styles/mixins/tiles";

  .apply-template {
    min-height: 300px;
    min-width: 500px;
    max-width: 80vw;
    padding-top: 30px;
  }

  .templates {
    @include tiles.tile__collection;
  }

  .template {
    @include tiles.tile;
    padding: 25px;
    max-width: 300px;

    user-select: none;
    cursor: pointer;

    &:hover {
      @include tiles.tile--active;
    }
  }

  .template__title {
    font-size: 16px;
    font-weight: bold;

    margin-bottom: 15px;
  }

  .template__description {
    font-size: 12px;
  }
