
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/label";

  .show-simple-unit {
    display: flex;

    border: 1px solid colors.$color_input--border;
  }

  .show-simple-unit__badge {
    width: 5px;
  }

  .show-simple-unit__infos {
    padding: 0 10px;
    padding-bottom: 5px;
  }

  .info__label {
    @include label.label;
  }
