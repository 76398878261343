@use "shared/styles/colors";
@use "./features";

body {
  // visual style
  color: colors.$color_text--global;
  background-color: colors.$color_background--global;
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @if features.$feature_heading--uppercase {
    text-transform: uppercase;
  }
}
h4 {
  font-size: 17px;
}

h5 {
  font-size: 15px;
  color: colors.$color_heading-five--text;
  margin: 30px 0 5px;
}

a {
  text-decoration: none;
  color: inherit;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: colors.$color_main-area--background;
}
