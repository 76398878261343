@use "shared/styles/colors";

@mixin label() {
  font-size: 12px;
  font-weight: bold;

  padding-bottom: 5px;
  padding-top: 10px;
}

@mixin label--error() {
  color: colors.$color_form__errors--text;
}
