
  @use "frontend/styles/mixins/link";
  @use "shared/styles/colors";

  .show-patient__age {
    font-size: 12px;
  }

  .show-patient__details-link {
    @include link.link;

    margin-top: 5px;

    font-size: 12px;
  }

  .show-patient__headline--no-distance {
    margin-bottom: 10px;
  }

  .actions__button {
    display: inline-block;
    padding: 10px;
  }

  .patient__title,
  .appointments__title {
    border-top: 1px solid colors.$color_base-list--separator;
    border-bottom: 1px solid colors.$color_base-list--separator;
    padding: 10px;
  }

  .patient__title {
    display: flex;
    margin: 20px 0;
  }

  .patientenprofil__title {
    margin: 0;
  }

  .show-title__name {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }

  .show-title__age {
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }

  .profile__wrapper {
    display: flex;
    margin-bottom: 30px;
  }

  .profile--header {
    text-align: right;
    margin-right: 20px;
    font-weight: bold;

    > div {
      padding: 5px;
    }
  }

  .profile__info div {
    padding: 5px;
  }

  .appointments__title {
    margin-top: 70px;
  }

  .action__button-enter-appointment {
    @include link.link;

    font-size: 12px;
    margin-left: 10px;
  }

  .action__button-create-appointment {
    margin-left: auto;
    width: 220px;
  }

  .action-icon {
    font-size: 20px;
    cursor: pointer;

    margin-left: 10px;
  }
