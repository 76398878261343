
  @use "shared/styles/colors";

  .base-menu__dropdown {
    padding: 16px;

    background-color: colors.$color_popup--background;

    display: flex;
    flex-direction: column;

    > div {
      border-radius: 0;
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .base-menu__open-button {
    // size and spacing
    padding: 5px;
    font-size: 30px;

    position: relative;
  }

  .base-menu__badge {
    font-size: 17px;
    color: colors.$color_badge;
  }

  .base-menu__badge--open-menu {
    position: absolute;
    right: 3px;
    bottom: 5px;
  }

  .profile-selector__item {
    cursor: pointer;

    padding: 2px 0;

    font-size: 12px;
  }

  .profile-selector__heading {
    font-size: 14px;
    font-weight: bold;
    margin-top: 15px;
  }
