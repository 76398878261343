
  @use "shared/styles/colors";

  .loading-text {
    display: flex;
    align-items: center;
  }

  .loading-text__text {
    margin-right: 10px;
  }

  .error-text {
    color: colors.$color_text--global--error;
    font-weight: bold;
  }

  .pvs-options {
    margin-bottom: 5px;
  }

  .configuration {
    padding: 40px 0;
  }
