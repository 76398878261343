
  @use "sass:color";
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/label";

  $_padding: 7px;

  .vue-component__form-input {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .form-input__input {
    width: 100%;
    padding: $_padding;
    font-size: 15px;
    line-height: 23px;
  }

  .form-input__input--small {
    font-size: 12px;
    padding: 0 #{$_padding};
  }

  .form-input__label,
  .form-input__label__error {
    @include label.label;
  }

  .form-input__label__error {
    @include label.label--error;
  }

  .form-input--full-width {
    width: 100%;
    padding: $_padding;
  }

  .form-input__completion {
    padding: 10px;
    border: 1px solid colors.$color_header_search--border;
    color: colors.$color_plain--text;

    cursor: pointer;

    &:hover {
      background: color.adjust(
        colors.$color_plain--background,
        $lightness: -20%
      );
    }
  }

  .form-input__completions {
    background: colors.$color_plain--background;
    position: absolute;
    border: 1px solid colors.$color_header_search--border;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: calc(100% - #{$_padding});
    left: $_padding;
    right: $_padding;
  }

  .form-input__status-container {
    display: flex;
    align-items: center;

    .form-input__input {
      flex: 1;
    }

    .form-input__animation-status {
      margin-top: 3px;
      margin-left: 10px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
