
  .base-popup {
    &,
    > .base-popup__target {
      // display: none;
      width: 100%;
    }
  }

  .base-popup__popup {
    position: absolute;

    // needed to mitigate ResizeObserver loop
    // see: https://github.com/floating-ui/floating-ui/issues/1740#issuecomment-1620002162
    width: max-content;
  }
