
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/label";

  .vue__datetime-picker {
    padding: 5px 0;

    .datetime-picker__clear-button {
      cursor: pointer;
      font-style: italic;
      font-size: 12px;
      margin-left: 10px;
    }

    .datetime-picker__container {
      border: 1px solid colors.$color_input--border;
      padding: 7px;
    }
    .datetime-picker__container--small {
      padding: 3px;
    }

    .datetime-picker__container--disabled {
      cursor: not-allowed;
    }
  }

  .form-datetime__label {
    @include label.label;
  }

  .datetime-picker__label__error {
    @include label.label;
    @include label.label--error;
  }
  .form-datetime__status-container {
    display: flex;

    align-items: center;

    .datetime-picker__container {
      flex: 1;
      width: 100%;
    }
  }

  .form-datetime__animation-status {
    margin-left: 10px;
    margin-top: 4px;
  }

  .datetime-picker__container--mobile {
    display: none;
  }

  :deep(.flatpickr-wrapper) {
    width: 100%;
  }
