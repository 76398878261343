
  @use "shared/styles/colors";

  .vue-route__abstract-calendar {
    // grid definition
    .main-grid {
      display: grid;
      /* prettier-ignore */
      grid-template:
        "topbar   action " auto
        "calendar sidebar" auto /
        auto      220px;
      gap: 22px;

      .main-grid__topbar {
        grid-area: topbar;
      }
      .main-grid__action {
        grid-area: action;
      }
      .main-grid__calendar {
        grid-area: calendar;
      }
      .main-grid__sidebar {
        grid-area: sidebar;
      }
    }

    .topbar {
      // positioning inside
      display: flex;
      justify-content: space-between;
      align-items: center;

      // for being stuck, see below
      box-sizing: border-box;
    }

    .topbar--stuck {
      background-color: colors.$color_main-area--background;
      z-index: 11;
      min-height: 50px;
    }

    .topbar__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      height: 31px;

      > div {
        margin-right: 22px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .topbar__time-navigator-button {
      // REFACTOR: overwritten button styles?
      padding-left: 7px;
      padding-right: 7px;

      .fe-arrow-left-boxed {
        // REFACTOR: dimension-variable?
        margin-right: 15px;
      }
      .fe-arrow-right-boxed {
        // REFACTOR: dimension-variable?
        margin-left: 15px;
      }
    }

    .calendar-view {
      margin-left: auto;
    }
  }
