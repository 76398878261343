@use "sass:color";

// ----------------------------------------------------------------------------------------------
//  color palette (names: https://www.color-name.com/hex/) (private)
// ----------------------------------------------------------------------------------------------
// color palette with named colors
// ONLY: use this colors throughout the application
// BUT: do not use them directly, see "semantic colors" and "specific color usages"

$_palette_teal-blue: #3b7d8b;
$_palette_deep-space-sparkle: #426479;
$_palette_june-bud: #cdcd47;
$_palette_white: #ffffff;
$_palette_green: #147f14;
$_palette_anti-flash-white: #f1f1f1;
$_palette_light-grey: #d5d5d5;
$_palette_quick-silver: #a6a6a6;
$_palette_spanish-grey: #9c9c9c;
$_palette_dark-charcoal: #333333;
$_palette_venetian-red: #cc1512;

// ----------------------------------------------------------------------------------------------
//  semantic colors (private)
// ----------------------------------------------------------------------------------------------

$_color-semantic_accent: $_palette_teal-blue;
$_color-semantic_highlight: $_palette_june-bud;
$_color-semantic_neutral: $_palette_anti-flash-white;
$_color-semantic_inverted: $_palette_white;
$_color-semantic_warn: $_palette_venetian-red;

$_color-semantic_text--default: $_palette_dark-charcoal;
$_color-semantic_text--accent: $_color-semantic_accent;
$_color-semantic_text--inverted: $_color-semantic_inverted;
$_color-semantic_text--warn: $_color-semantic_warn;
$_color-semantic_text--disabled: color.adjust(
  $_palette_spanish-grey,
  $alpha: 0.9,
  $lightness: -20%
);

$_color-semantic_background--accent: $_color-semantic_accent;
$_color-semantic_background--neutral: $_color-semantic_neutral;
$_color-semantic_background--highlight: $_color-semantic_highlight;
$_color-semantic_background--inverted: $_color-semantic_inverted;
$_color-semantic_background--disabled: color.adjust(
  $_palette_spanish-grey,
  $alpha: 0.7,
  $lightness: 20%
);
$_color-semantic_background--warn: $_color-semantic_warn;

// ----------------------------------------------------------------------------------------------
//  specific color usages (public)
// ----------------------------------------------------------------------------------------------

$color_main-area--background: $_palette_teal-blue; // area below the header
$color_main-area--text: $_color-semantic_neutral; // area below the header

$color_brand-name--text: $_color-semantic_accent;

$color_text--global: $_color-semantic_text--default;
$color_text--global--error: $_color-semantic_warn;
$color_background--global: $_color-semantic_background--neutral;

$color_plain--background: $_palette_white;
$color_plain--text: $color_text--global;

$color_header--background: $_palette_white;
$color_header--background--contrast: $_color-semantic_neutral;
$color_header--text: $_color-semantic_text--accent;

$color_button--background: $_color-semantic_background--accent;
$color_button--text: $_color-semantic_inverted;
$color_button__action--background: $_color-semantic_background--highlight;
$color_button__action--text: $color_button--text;
$color_button__warn--background: $_color-semantic_warn;
$color_button__warn--text: $color_button--text;
$color_button__inverted--background: $color_button--text;
$color_button__inverted--text: $color_button--background;
$color_button__icon-action--text: $color_button__inverted--text;

$color_search__button: $color_text--global;

$color_box--background: $color_background--global;
$color_box__light--background: $_color-semantic_background--inverted;
$color_box--text: $color_text--global;
$color_box__heading--text: $color_text--global;
$color_box__heading--separator: $_color-semantic_accent;

$color_calendar--text: $color_text--global;
$color_calendar--label: $_palette_deep-space-sparkle;
$color_calendar--cell-border: color.adjust(
  $_color-semantic_accent,
  $alpha: -0.5
);
$color_calendar__attendance--background: color.adjust(
  $_color-semantic_accent,
  $alpha: -0.8
);
$color_calendar__unit--background: $_color-semantic_background--inverted;
$color_calendar__unit--highlight-background: color.change(
  $_color-semantic_background--highlight,
  $lightness: 90%
);
$color_calendar__unit--hover-on--outline: color.scale(
  $_color-semantic_highlight,
  $alpha: -50%
);
$color_calendar__unit__disabled--text: $_color-semantic_text--disabled;
$color_calendar__unit__disabled--background: $_color-semantic_background--disabled;
$color_calendar__unit__icon--text: $_color-semantic_text--accent;
$color_calendar__unit__praxisapp-icon--text: $_palette_spanish-grey;
$color_calendar__unit__diagonal-stripes--c1: color.scale(
  $_color-semantic_warn,
  $alpha: -65%
);
$color_calendar__unit__diagonal-stripes--c2: color.scale(
  $_color-semantic_neutral,
  $alpha: -50%
);
$color_calndar__tmp-unit--text: $_color-semantic_text--disabled;
$color_calendar__settings-attendance--background: $color_calendar__attendance--background;
$color_calendar__settings-attendance--background--hover: color.scale(
  $_color-semantic_highlight,
  $lightness: 90%
);
$color_calendar__settings-attendance__border: $_palette_light-grey;
$color-calendar__visual-line: color.scale(
  $color_calendar--cell-border,
  $lightness: 50%
);

$color_mini-calendar-day--background: $_palette_light-grey;
$color_mini-calendar-day--text: $_color-semantic_text--default;
$color_mini-calendar-day__light--text: $_palette_quick-silver;
$color_mini-calendar-day__light--background: $_palette_white;
$color_mini-calendar-day__selected--text: $_color-semantic_inverted;
$color_mini-calendar-day__selected--background: $_color-semantic_accent;
$color_mini-calendar-day__highlighted--border: $_color-semantic_highlight;
$color_mini-calendar-day__today: $_palette_venetian-red;

$color_create-appointment__box-hover-selection--text: $_color-semantic_text--inverted;
$color_create-appointment__box-hover-selection--background: $_color-semantic_background--accent;
$color_create-appointment__box--border: $_color-semantic_inverted;
$color_create-appointment__box--background: $_color-semantic_background--neutral;
$color_create-appointment__box-new--text: $_color-semantic_inverted;
$color_create-appointment__box-new--background: $_palette_quick-silver;
$color_create_appointment__box__warning--text: $_color-semantic_text--warn;

$color_wizard__step--label: $_color-semantic_text--default;
$color_wizard__step--indicator: $_color-semantic_accent;
$color_wizard__step--hover-indicator: color.scale(
  $color_wizard__step--indicator,
  $lightness: -35%
);
$color_wizard__step-inactive--indicator: color.scale(
  $color_wizard__step--indicator,
  $lightness: 60%
);
$color_wizard__step-inverted--label: $_color-semantic_text--inverted;
$color_wizard__step-inverted--indicator: $_color-semantic_highlight;
$color_wizard__step-inverted--hover-indicator: color.scale(
  $color_wizard__step-inverted--indicator,
  $lightness: -35%
);
$color_wizard__step-inverted-inactive--indicator: color.scale(
  $color_wizard__step-inverted--indicator,
  $lightness: 60%
);

$color_form__errors--text: $_color-semantic_text--warn;
$color_login-form__errors--text: $color_form__errors--text;
$color_form-color__preview--border: color.adjust(
  $_color-semantic_accent,
  $alpha: -0.5
);

$color_form-select--background: $_color-semantic_background--accent;
$color_form-select--text: $_color-semantic_text--inverted;
$color_form-select__chooser--background: $_color-semantic_background--neutral;
$color_form-select__chooser--text: $_color-semantic_text--default;
$color_form-select__option--background: $color_form-select--background;

$color_sidebar--background: $_palette_white;
$color_sidebar--text: $_color-semantic_text--default;

$color_settings--background: $_color-semantic_background--neutral;
$color_settings--text: $color_sidebar--text;
$color_settings__box-border: $_color-semantic_accent;

$color_spinner__main: $_color-semantic_accent;
$color_spinner__main--transparent: color.scale(
  $color_spinner__main,
  $alpha: -80%
);
$color_spinner__main-inverted: $_color-semantic_inverted;
$color_spinner__main-inverted--transparent: color.scale(
  $_color-semantic_inverted,
  $alpha: -80%
);

$color_table-like__header--text: $_color-semantic_text--inverted;
$color_table-like__header--background: $_color-semantic_background--accent;

$color_input--border: $_color-semantic_text--default;

$color_pillar-calendar__droplet--background: $_color-semantic_background--accent;
$color_pillar-calendar__droplet--text: $_color-semantic_text--inverted;
$color_pillar-calendar__droplet-empty--background: $_color-semantic_background--inverted;
$color_pillar-calendar__heading-separator: $color_box__heading--separator;

$color_link--text: $_color-semantic_text--accent;
$color_link--optional-hover: $_color-semantic_highlight;
$color_link--optional-background: $_palette_white;

$color_patient-search__patient--background: $_color-semantic_background--neutral;
$color_patient-search__patient--border: $_color-semantic_inverted;

$color_heading-five--text: $_color-semantic_text--accent;

$color_popup--background: $_color-semantic_background--inverted;

$color_modal--background: $_color-semantic_background--inverted;
$color_modal--text: $_color-semantic_text--default;

$color_base-columns--separator: $_palette_light-grey;

$color_header_search--border: $_color-semantic_accent;

$color_toast__icon--text: $_color-semantic_text--inverted;
$color_toast--error--background: $_color-semantic_background--warn;
$color_toast--success--background: $_color-semantic_background--highlight;

$color_animation-status--success--text: $_color-semantic_background--highlight;
$color_animation-status--error--text: $_color-semantic_text--warn;

$color_help--icon: $_color-semantic_text--accent;

$color_badge: $_color-semantic_warn;

$color_settings-sessions--border: $_palette_quick-silver;
$color_settings-sessions--icon: $_palette_quick-silver;

$color_tile--hover--background: $_color-semantic_background--accent;
$color_tile--hover--text: $_color-semantic_text--inverted;

$color_tooltip--background: $_palette_dark-charcoal;
$color_tooltip--text: $_color-semantic_text--inverted;

$color_event-entry--background: $_palette_light-grey;
$color_event-entry--background-seen: $_palette_anti-flash-white;

$color_operator-tag--background: $_color-semantic_background--accent;
$color_operator-tag--text: $_color-semantic_text--inverted;
$color_operator-tag--no-selection: $_color-semantic_warn;
$color_operator-selector--hover: $_palette_light-grey;
$color_operator-selector_popup--background: $_color-semantic_background--inverted;

$color_filter-container__accordion--border: $_palette_light-grey;
$color_filter-container__accordion--label: $_palette_quick-silver;

$color-now-indicator--text: $_palette_venetian-red;

$color_inline-editable__border: $_palette_light-grey;
$color_inline-editable__outline: $_palette_dark-charcoal;

$color_base-resize-bar--separator: $_palette_light-grey;
$color_base-resize-bar--separator--resizing: $_color-semantic_highlight;

$color_base-list--separator: $_palette_light-grey;

$color_confirm_absence__warning--text: $_color-semantic_text--warn;

$color_edit-action: $_color-semantic_text--accent;

$color_context-menu--text: $_color-semantic_text--default;
$color_context-menu--background: $_palette_anti-flash-white;
$color_context-menu--shadow: $_palette_dark-charcoal;
$color_context-menu--hover: $_color-semantic_background--accent;
$color_context-menu--hover--text: $_color-semantic_text--inverted;
$color_context-menu--simple-entry--icon-color: $_color-semantic_text--accent;

$color_holidays-table-header--background: $_color-semantic_background--neutral;
$color_red-note--text: $_color-semantic_text--warn;
$color_red-note--background: color.adjust(
  $color_red-note--text,
  $alpha: -0.67,
  $lightness: -10%
);

$color_stripes--background: $_palette_light-grey;

$color_green-note--text: $_palette_green;
$color_green-note--background: color.adjust(
  $color_green-note--text,
  $alpha: -0.67,
  $lightness: -10%
);

$color_base-list__light--text: $_palette_quick-silver;
$color_base-list__hover--background: color.scale(
  $_palette_quick-silver,
  $lightness: 90%
);
