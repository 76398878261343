/* stylelint-disable block-no-empty -- empty selectors are for documentation */

@use "shared/styles/colors";

.toast {
}
.toast--success {
  background-color: colors.$color_toast--success--background !important;
}
.toast--error {
  background-color: colors.$color_toast--error--background !important;
}

.toast__icon {
  color: colors.$color_toast__icon--text !important;
}
.toast__icon--success {
}
.toast__icon--error {
}
