
  @use "shared/styles/colors";

  .base-columns__columns-container {
    display: flex;
  }

  .base-columns__column {
    $_line-distance: 20px;

    margin-right: $_line-distance;
    padding-right: $_line-distance;

    border-right: 1px solid colors.$color_base-columns--separator;

    &:last-of-type {
      margin-right: 0;
      padding-right: 0;

      border-right: none;
    }
  }

  .base-column__heading--no-upper-distance {
    margin-top: 0;
  }
