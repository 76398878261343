
  @use "sass:color";
  @use "shared/styles/colors";

  .context-menu {
    margin-top: 1px; // prevent instant-hover on opening

    background-color: colors.$color_context-menu--background;
    box-shadow: 0 0 4px 1px
      #{color.scale(colors.$color_context-menu--shadow, $alpha: -50%)};
    color: colors.$color_context-menu--text;
    font-size: 14px;
    min-width: 150px;
  }

  .context-menu__category--divider {
    border-top: 1px solid colors.$color_base-list--separator;
  }
