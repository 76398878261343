
  .topbar__button-container {
    display: flex;
    margin-bottom: 10px;
    > div {
      margin-right: 22px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .topbar__time-navigator-button {
    // REFACTOR: overwritten button styles?
    padding-left: 7px;
    padding-right: 7px;

    .fe-arrow-left-boxed {
      // REFACTOR: dimension-variable?
      margin-right: 15px;
    }
    .fe-arrow-right-boxed {
      // REFACTOR: dimension-variable?
      margin-left: 15px;
      margin-right: 5px;
    }
  }

  .topbar__container {
    display: flex;
    margin: 20px 0;
  }

  .topbar__attendance-view {
    margin-left: auto;
  }

  .topbar__time-navigator {
    display: flex;
    flex-direction: column;
  }

  .topbar__mini-calendar {
    width: 220px;
    margin-top: 10px;
    align-self: center;
  }
