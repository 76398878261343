@font-face {
	font-family: "frontend";
	font-display: block;
	src: url("/packs/iconfont.frontend.6102df595ae4d537ee3ae7a4c99ec210.eot?#iefix") format("embedded-opentype"),
url("/packs/iconfont.frontend.5461856ef57cb6241b2070063557f173.woff") format("woff"),
url("/packs/iconfont.frontend.10d88f9d972e8666355888b41de6a5ff.woff2") format("woff2"),
url("/packs/iconfont.frontend.306e81a4389fcb6a3f649a7964aeb769.ttf") format("truetype"),
url("/packs/iconfont.frontend.cff2db4a127a49833d3b6ef1d317194c.svg#frontend") format("svg");
}

.fe {
	line-height: 1;
}

.fe:before {
	font-family: frontend !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
}

.fe-magnifier:before {
	content: "\f101";
}
.fe-cog:before {
	content: "\f102";
}
.fe-arrow-left-boxed:before {
	content: "\f103";
}
.fe-arrow-right-boxed:before {
	content: "\f104";
}
.fe-alarm:before {
	content: "\f105";
}
.fe-head:before {
	content: "\f106";
}
.fe-calendar-day:before {
	content: "\f107";
}
.fe-arrow-left:before {
	content: "\f108";
}
.fe-arrow-right:before {
	content: "\f109";
}
.fe-circle:before {
	content: "\f10a";
}
.fe-circle-with-dot:before {
	content: "\f10b";
}
.fe-plus--with-circle--inverted:before {
	content: "\f10c";
}
.fe-pencil:before {
	content: "\f10d";
}
.fe-close:before {
	content: "\f10e";
}
.fe-close--with-circle:before {
	content: "\f10f";
}
.fe-checkmark:before {
	content: "\f110";
}
.fe-checkmark--with-circle:before {
	content: "\f111";
}
.fe-logout:before {
	content: "\f112";
}
.fe-hamburger:before {
	content: "\f113";
}
.fe-trash:before {
	content: "\f114";
}
.fe-info--with-circle:before {
	content: "\f115";
}
.fe-circle--filled:before {
	content: "\f116";
}
.fe-briefcase:before {
	content: "\f117";
}
.fe-smartphone:before {
	content: "\f118";
}
.fe-smartphone--touch:before {
	content: "\f119";
}
.fe-star--with-badge:before {
	content: "\f11a";
}
.fe-clipboard--clock:before {
	content: "\f11b";
}
.fe-exclamation--with-triangle:before {
	content: "\f11c";
}
.fe-chevron-up:before {
	content: "\f11d";
}
.fe-chevron-down:before {
	content: "\f11e";
}
.fe-funnel:before {
	content: "\f11f";
}
.fe-note-clinical:before {
	content: "\f120";
}
.fe-drag:before {
	content: "\f121";
}
.fe-stack:before {
	content: "\f122";
}
.fe-person--off:before {
	content: "\f123";
}
.fe-print:before {
	content: "\f124";
}
.fe-schedule:before {
	content: "\f125";
}
