
  @use "shared/styles/colors";

  .settings-attendance {
    display: flex;

    cursor: pointer;

    border: 1px solid colors.$color_calendar__settings-attendance__border;
    background-color: colors.$color_calendar__settings-attendance--background;

    padding: 7px 3px;

    font-size: 14px;

    &:hover,
    &.settings-attendance--hovered {
      background-color: colors.$color_calendar__settings-attendance--background--hover;
    }
  }

  .settings-attendance__person {
    writing-mode: vertical-lr;

    margin-right: 5px;

    font-size: 12px;
    font-weight: bold;
  }

  .settings-attendance__name {
    hyphens: auto;
  }

  .settings-attendance__name--flip {
    writing-mode: vertical-lr;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1.5;
  }
