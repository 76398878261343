
  @use "shared/styles/colors";

  .holiday-selection--as-modal {
    width: 80vw;
    min-height: 80vh;
  }

  .holidays__wrapper {
    margin: 50px 0;
    height: 60vh;
    overflow-y: auto;
    border: solid 1px colors.$color_base-list--separator;
  }

  .holidays__row {
    display: flex;
    flex-flow: row wrap;
    border: solid 1px colors.$color_base-list--separator;
  }

  .holidays--header {
    position: sticky;
    top: 0;
    background-color: colors.$color_holidays-table-header--background;
  }

  .holidays__cell {
    flex: 1;
    padding: 8px;
  }

  .holidays__first-cell,
  .holidays__last-cell {
    width: 80px;
    padding: 8px;
  }

  .holidays__first-cell {
    border-right: solid 1px colors.$color_base-list--separator;
  }

  .holidays-new-year {
    border-top: 2px solid colors.$color_inline-editable__outline;
  }
