@use "sass:color";

@use "shared/styles/colors";

@mixin cell($scale: 1) {
  width: $scale * 25px;
  height: $scale * 25px;
  margin-right: 5px;
  font-weight: bold;
  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin day($interactive: true) {
  background-color: colors.$color_mini-calendar-day--background;
  color: colors.$color_mini-calendar-day--text;

  @if $interactive {
    cursor: pointer;
    &:hover {
      background-color: color.adjust(
        colors.$color_mini-calendar-day--background,
        $lightness: -5%
      );
    }
  }
}

@mixin day--selected($interactive: true) {
  color: colors.$color_mini-calendar-day__selected--text;
  background-color: colors.$color_mini-calendar-day__selected--background;

  @if $interactive {
    &:hover {
      background-color: color.adjust(
        colors.$color_mini-calendar-day__selected--background,
        $lightness: -5%
      );
    }
  }
}

@mixin day--highlighted($interactive: true) {
  border: 2px solid colors.$color_mini-calendar-day__highlighted--border;
  background-color: colors.$color_mini-calendar-day__highlighted--border;
  color: colors.$color_mini-calendar-day__selected--text;
  box-sizing: border-box;
  @if $interactive {
    &:hover {
      background-color: color.adjust(
        colors.$color_mini-calendar-day__highlighted--border,
        $lightness: -5%
      );
    }
  }
}
