
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/label";

  .form-color {
    display: flex;
    align-items: flex-end;

    > div {
      margin-right: 20px;
    }
  }

  .form-color__preview {
    width: 30px;
    height: 30px;

    cursor: pointer;

    border: 1px solid colors.$color_form-color__preview--border;
  }

  .form-color__action {
    font-size: 10px;
  }

  .form-color__actions {
    display: flex;
    flex-direction: column;

    > .form-color__action {
      margin-bottom: 5px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .form-color__swatches {
    // height: 50px;
    width: 50px;
    display: flex;
    flex-flow: row wrap;

    margin-bottom: 10px;
  }

  .form-color__swatch {
    width: 20px;
    height: 20px;
    margin-top: 5px;
    margin-right: 5px;

    cursor: pointer;
  }

  .form-color__sneak-peak {
    margin-bottom: 10px !important;

    text-align: center;
    padding: 5px;
    font-weight: bold;
  }

  .form-color__label__error {
    @include label.label;
    @include label.label--error;
  }
