
  .eo__help-text {
    margin-top: 30px;
  }
  .eo__duration-pickers {
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 20px;
    }
  }
