
  @use "shared/styles/colors";

  .edit-appointment-type {
    margin-bottom: 15px;
  }

  .eat__header-open-text {
    display: inline-block;

    color: colors.$color_plain--text;
    font-size: 11px;
    font-weight: normal;
    font-style: italic;
    text-transform: none;

    margin-left: 20px;
  }

  .eat__header {
    margin: 10px 0;
  }

  .eat__time-pickers-line {
    display: flex;
    flex-wrap: wrap;

    > div {
      margin-right: 20px;
    }
  }

  .eat__spacer {
    margin-bottom: 100px;
  }

  .sessions {
    display: flex;
    flex-wrap: wrap;
  }

  .session {
    max-width: 380px;
    margin-right: 30px;
    padding-right: 30px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-right: 1px solid colors.$color_settings-sessions--border;

    &:last-of-type {
      border-right: none;
    }
  }

  .session__deletion-link {
    color: colors.$color_link--text;
    font-size: 12px;
    cursor: pointer;
  }

  .sessions__add {
    display: flex;
    flex-direction: column;
    justify-content: center;

    min-height: 300px;

    cursor: pointer;

    &:hover {
      &,
      i {
        color: colors.$color_link--text;
      }
    }
  }

  .sessions__add__icon {
    color: colors.$color_settings-sessions--icon;
    font-size: 50px;
  }

  .sessions__add__text {
    font-size: 14px;
    font-style: italic;
  }

  .sessions__add__icon,
  .sessions__add__text {
    text-align: center;
  }
