
  @use "frontend/styles/dimensions";
  @use "shared/styles/colors";
  @use "frontend/styles/variables";

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    background-color: colors.$color_header--background;
    color: colors.$color_header--text;
    box-shadow: variables.$box-shadow_menus;
  }

  .header--fixed {
    position: fixed;

    left: 0;
    right: 0;
    top: 0;

    z-index: 100;
  }

  .header__right,
  .header__left {
    flex: 1;
    padding: 0 dimensions.$dimension_main--padding-lr;

    display: flex;
    align-items: center;

    min-height: 65px;
  }
  .header__left {
    margin-right: 20px;
  }

  .header__search {
    flex: 1;
    margin-right: 20px;
    min-width: 176px;
  }

  .header__reloading {
    margin-left: 20px;
    font-size: 12px;

    cursor: pointer;
  }
