
  @use "shared/styles/colors";

  .navigation-button__container {
    padding: 15px 0;
    width: 180px;
    margin: 0 auto; // center horizontally

    text-align: center;
  }

  .modal__text {
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 80vw;
  }

  .modal__text--error {
    color: colors.$color_form__errors--text;
  }

  .navigation-button__neuer-termin {
    padding: 22px;
    font-size: 20px;
    margin: 40px 0;
  }
