
  @use "shared/styles/colors";
  @use "frontend/styles/features";

  .brand {
    // positioning inside
    display: flex;
    align-items: center;
  }

  .brand__name {
    // visual style
    font-size: 27px;

    @if features.$feature_branding--uppercase {
      text-transform: uppercase;
    }
    font-weight: bold;
    font-family: "Open Sans Condensed", sans-serif;
    color: colors.$color_brand-name--text;

    white-space: nowrap;
  }

  .brand__logo {
    height: 40px;
    margin-right: 10px;
  }
