
  @use "frontend/styles/mixins/drag";

  .settings-container__new-entry {
    margin-top: 35px;
  }

  .draggable {
    @include drag.draggable;
  }
