
  @use "sass:color";
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/mini-calendar";

  .mini-calendar__week,
  .mini-calendar__headers {
    display: flex;
  }

  .mini-calendar__week {
    margin-bottom: 5px;
  }

  .mini-calendar__day,
  .mini-calendar__header {
    @include mini-calendar.cell;
  }

  .mini-calendar__day {
    @include mini-calendar.day;
  }

  .mini-calendar__day--light {
    background-color: colors.$color_mini-calendar-day__light--background;
    color: colors.$color_mini-calendar-day__light--text;

    &:hover {
      background-color: color.adjust(
        colors.$color_mini-calendar-day__light--background,
        $lightness: -5%
      );
    }
  }

  .mini-calendar__day--highlighted {
    @include mini-calendar.day--highlighted;
  }

  .mini-calendar__day--selected {
    @include mini-calendar.day--selected;
  }

  .mini-calendar__day--holiday {
    border: 2px solid colors.$color_red-note--text;
  }

  .mini-calendar__day--today {
    color: colors.$color_mini-calendar-day__today;
  }
