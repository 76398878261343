
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/mini-calendar";

  .mini-calendar__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;

    i {
      color: colors.$color_header--text;

      cursor: pointer;
      font-size: 17px;
    }
  }

  .mini-calendar__actions {
    display: flex;
    margin-top: 10px;
  }

  .mini-calendar__rotation {
    display: flex;
    margin-right: 5px;
  }

  .mini-calendar__rotation-icon {
    @include mini-calendar.cell($scale: 0.75);
    @include mini-calendar.day($interactive: false);

    margin-right: 2px;
  }

  .mini-calendar__rotation-icon--highlighted {
    @include mini-calendar.day--highlighted($interactive: false);
  }

  .mini-calendar__rotation-icon--selected {
    @include mini-calendar.day--selected($interactive: false);
  }

  .mini-calendar__rotation-arrow {
    cursor: pointer;

    .fe {
      font-size: 19px;
    }
  }
