
  @use "shared/styles/colors";

  .event-base {
    background-color: colors.$color_event-entry--background;

    padding: 20px 15px;

    margin: 20px 0;
  }

  .event-base--seen {
    background-color: colors.$color_event-entry--background-seen;
  }

  .event-base__header {
    font-size: 10px;
    font-weight: bold;

    margin-bottom: 15px;
  }

  .event-base__title {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .event-base__text {
    font-size: 14px;
  }

  .header__date {
    margin-right: 10px;
  }

  .header__user {
    font-weight: normal;
  }
