
  @use "shared/styles/colors";
  .list-item {
    display: flex;
    padding: 15px;
    margin: 7px;
    border: 1px solid colors.$color_base-list--separator;
    border-radius: 5px;

    &:hover {
      background-color: colors.$color_base-list__hover--background;
    }

    &__select {
      cursor: pointer;
    }

    &__flush {
      margin: 0;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid colors.$color_base-list--separator;
    }

    &__title {
      margin: 0;
      display: inline;
    }

    &__subtitle {
      font-size: 14px;
    }

    &__description {
      display: inline;
      font-size: 14px;
    }

    &__text {
      color: colors.$color_base-list__light--text;
      margin-top: 2px;
      font-size: 12px;
    }

    &__icons {
      margin-left: auto;
      align-self: center;
      cursor: pointer;
    }

    &__icon {
      margin: 0 5px;
      padding: 4px 7px;
      border-radius: 4px;
      border: 1px solid colors.$color_base-list--separator;

      &:hover {
        color: colors.$color_main-area--background;
      }
    }

    &__link {
      font-weight: bold;
      margin: 0 10px;
    }
  }

  .list-item--small {
    padding: 5px;
  }
