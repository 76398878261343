
  @use "shared/styles/colors";

  .appointment-list--as-modal {
    width: 90vw;
    min-height: 90vh;
  }

  .columns {
    display: flex;
    align-items: center;
  }
  .columns__column {
    width: 120px;
    margin: 0 10px;
  }

  .appointment-list__date {
    font-size: 14px;
    margin-top: 30px;
    font-weight: bold;
  }

  .appointment-list__icon {
    padding: 4px 0;
    color: colors.$color_calendar__unit__icon--text;
  }
  .icon--has-praxisapp {
    padding: 4px 0;
    color: colors.$color_calendar__unit__praxisapp-icon--text;
  }
  .appointment-list--highlight-background {
    background-color: colors.$color_calendar__unit--highlight-background;
  }
  .appointment-list--hover-on {
    background-color: colors.$color_base-list__hover--background;
  }

  .appointment-list__flex-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
  }
  .pdf-icon {
    margin-left: auto;
  }
