
  @use "shared/styles/colors";
  @use "frontend/styles/dimensions";
  @use "frontend/styles/variables";

  .vue-route__settings--index {
    flex: 1;

    display: flex;
    min-height: inherit;
  }

  .sidebar {
    min-height: inherit;
    width: 300px;
    color: colors.$color_sidebar--text;
    background-color: colors.$color_sidebar--background;

    padding: dimensions.$dimension_main--padding-tb
      dimensions.$dimension_main--padding-lr;

    box-shadow: variables.$box-shadow_menus;
    z-index: 1;
  }

  nav ul > li {
    margin: 10px 0;
  }

  .nav__li--sublevel {
    margin-left: 20px;
  }

  .settings-edit {
    flex: 1;

    color: colors.$color_settings--text;
    background-color: colors.$color_settings--background;

    padding: dimensions.$dimension_main--padding-tb
      dimensions.$dimension_main--padding-lr;
  }

  .office-blocking {
    margin-top: 50px;

    text-align: center;
  }

  .office-blocking__description {
    text-align: initial;
  }

  .back__to-calendar {
    font-size: 14px;
    margin-bottom: 40px;
  }
