
  @use "shared/styles/colors";

  .inline-editable {
    display: inline-block;

    cursor: text;

    border: 1px solid colors.$color_inline-editable__border;
    padding: 5px 40px;
  }

  .inline-editable--editing {
    outline: 1px solid colors.$color_inline-editable__outline;
  }

  .inline-editable__input {
    display: inline;

    border: none;
    outline: none;
  }
