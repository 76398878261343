
  @use "shared/styles/colors";

  .patient__wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .patient {
    display: flex;
    justify-content: space-between;

    cursor: pointer;
    padding: 8px;
    background-color: colors.$color_patient-search__patient--background;
    border-bottom: 1px colors.$color_patient-search__patient--border solid;
  }

  .patient--header,
  .patient:hover {
    background-color: colors.$color_table-like__header--background;
    color: colors.$color_table-like__header--text;
  }

  .patient__info {
    flex: 1;
  }

  .icon {
    flex: 0 1 19px;
    margin-right: 10px;
  }
