
  @use "shared/styles/colors";

  @keyframes animation-status {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .animation-status {
    font-size: 20px;

    display: inline-block;

    min-height: 25px;
  }

  .animation-status--visual-small {
    font-size: 12px;
    min-height: 12px;

    .animation-status__spinner {
      transform: scale(0.55);
      height: 12px;
    }
  }

  .animation-status--success {
    color: colors.$color_animation-status--success--text;
  }
  .animation-status--error {
    color: colors.$color_animation-status--error--text;
  }

  .animation-status__animation {
    animation-name: animation-status;
    animation-duration: 0.5s;
  }
