
  @use "shared/styles/colors";

  .tooltip {
    color: colors.$color_tooltip--text;
    background-color: colors.$color_tooltip--background;

    font-size: 12px;

    padding: 7px;
  }
