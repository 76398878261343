
  .base-resizer {
    position: relative;
  }

  .resize-bar {
    position: absolute;
    left: 0;
    right: 0;
  }

  .resize-bar--upper {
    top: 0;
  }

  .resize-bar--lower {
    bottom: 0;
  }
