
  @use "frontend/styles/mixins/label";
  .schedule-activation--as-modal {
    width: 50vw;
    min-height: 50vh;
  }
  .label--error {
    @include label.label;
    @include label.label--error;
  }
