
  @use "shared/styles/colors";

  .operator-tag {
    background-color: colors.$color_operator-tag--background;
    color: colors.$color_operator-tag--text;

    font-size: 10px;
    padding: 2px 5px;
    margin: 0 5px;
  }
