
  @use "shared/styles/colors";

  .base-help {
    display: flex;
    align-items: center;

    margin-bottom: 15px;
  }

  .base-help__icon {
    color: colors.$color_help--icon;

    font-size: 28px;
    margin-right: 9px;
  }

  .base-help__text {
    font-size: 13px;
    font-weight: 500;
  }
