@use "~@fontsource/open-sans-condensed/scss/mixins" as mixins;

@include mixins.fontFace(
  $weight: 300,
  $style: normal
); // css-weight: normal -> 400
@include mixins.fontFace(
  $weight: 700,
  $style: normal
); // css-weight: bold   -> 700

@include mixins.fontFace(
  $weight: 300,
  $style: italic
); // css-weight: normal -> 400
