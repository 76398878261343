
  @use "shared/styles/colors";

  .tmp {
    background-color: colors.$color_calendar__unit--background;

    font-size: 14px;

    &:hover {
      outline: 3px solid colors.$color_calendar__unit--hover-on--outline;
      z-index: 4 !important;
      left: 0 !important;
      right: 0 !important;

      transition: 50ms;
      transition-delay: 400ms;
      transition-property: left, right;
    }
  }

  .tmp__popup {
    min-width: 330px;
    overflow-y: auto;
  }

  .calendar__entry__mock {
    font-size: 11px;

    padding-left: 10px;

    color: colors.$color_calndar__tmp-unit--text;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
