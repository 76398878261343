
  @use "frontend/styles/mixins/label";

  .form-richedit__label {
    @include label.label;
  }

  .form-richedit__actions-compartment {
    .form-richedit__menu-action {
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .form-richedit__content {
    margin-top: 10px;
    border: 1px solid #777777; // TODO: unify border colors among application wrt input fields
    padding: 0 10px;

    /* stylelint-disable-next-line selector-class-pattern -- comes from external library */
    :deep(.ProseMirror) {
      outline: none;
      cursor: text;
    }
  }
