// ----------------------------------------------------------------------------------------------
//  general dimensions (private)
// ----------------------------------------------------------------------------------------------
$_dimension_border-radius: 3px;

// ----------------------------------------------------------------------------------------------
//  specific dimensions (public)
// ----------------------------------------------------------------------------------------------
$dimension_button--border-radius: $_dimension_border-radius;
$dimension_box--border-radius: $_dimension_border-radius;
$dimension_box--calendar-radius: $_dimension_border-radius;
$dimension_pillar-calendar--droplet-radius: $_dimension_border-radius;
$dimension_modal--border-radius: $_dimension_border-radius;

$dimension_main--padding-lr: 20px;
$dimension_main--padding-tb: 20px;
