
  .move-appointment {
    width: 80vw;
    min-height: 80vh;
  }

  .move-appointment__loading {
    margin-top: 50px;
  }

  .move-appointment__spacer-1 {
    margin-bottom: 30px;
  }

  .termin-info {
    margin: 5px 0;
  }
