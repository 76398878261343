
  @use "frontend/styles/mixins/label";

  .columns {
    display: flex;
  }

  .columns__column {
    flex: 1;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .checkboxes {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .label--error {
    @include label.label;
    @include label.label--error;
  }
