
  @use "frontend/styles/mixins/label";
  @use "frontend/styles/mixins/drag";

  .fcg__label,
  .fcg__label__error {
    @include label.label;
  }

  .fcg__label__error {
    @include label.label--error;
  }

  .fcg__status-container {
    display: flex;
    align-items: flex-start;
  }

  .fcg__animation-status {
    margin-left: 10px;
    margin-top: 5px;
  }

  .fcg__checkbox-container--rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > div {
      margin-right: 7px;
    }

    &.fcg__checkbox-container--visual-small > div {
      margin-right: 2px;
      flex: 1;
    }
  }
  .draggable {
    @include drag.draggable;
  }
