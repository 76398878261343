
  @use "shared/styles/colors";
  @use "frontend/styles/dimensions";
  @use "frontend/styles/features";
  @use "sass:color";

  .calendar {
    display: grid;

    background-color: colors.$color_box--background;

    border-radius: dimensions.$dimension_box--calendar-radius;
  }

  .calendar__cell {
    height: 100%;

    // positioning inside
    position: relative;

    // NOTE: If you change the width of the border,
    //       see use-day-view-parser.ts to adjust
    //       heightPixels in attendances too
    /* stylelint-disable-next-line order/order */ // TODO: Fix this
    $_border-definition: 1px solid colors.$color_calendar--cell-border;
    border-left: $_border-definition;
    border-bottom: $_border-definition;
  }
  .calendar__slot {
    height: 100px;
  }

  .calendar__cell--left {
    border-left: none;
  }
  .calendar__cell--top {
    background-color: colors.$color_box--background;

    &.calendar__cell--left {
      border-top-left-radius: dimensions.$dimension_box--calendar-radius;
    }
    &.calendar__cell--right {
      border-top-right-radius: dimensions.$dimension_box--calendar-radius;
    }
  }

  .calendar__cell-bottom {
    border-bottom: none;
  }
  .calendar__time-label {
    text-align: center;

    color: colors.$color_calendar--label;

    font-size: 14px;
  }
  .calendar__person {
    text-align: center;

    @if features.$feature_calendar-person--uppercase {
      text-transform: uppercase;
    }

    color: colors.$color_calendar--label;

    font-weight: bold;

    padding: 14px 0;
  }
  .calendar__peak-cell {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;

    color: colors.$color_calendar--label;
  }
  .calendar__cell--stuck {
    // if a header cell gets stuck at the top,
    // bring it to foreground
    z-index: 10;
    background-color: colors.$color_box--background;
  }

  .calendar__note__cell {
    font-size: 12px;
    text-align: center;
    color: colors.$color_calendar--label;
  }

  .calendar__note__cell__all-day {
    padding: 4px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .calendar__note-container {
    padding: 4px 0;
    display: flex;
    align-items: center;
  }

  .calendar__note {
    border-radius: 8px;
    padding: 2px 0;
    position: absolute;
  }

  .calendar__note__color-red {
    background-color: colors.$color_red-note--background;
    color: colors.$color_red-note--text;
  }

  .calendar__note__color-green {
    background-color: colors.$color_green-note--background;
    color: colors.$color_green-note--text;
  }

  .calendar__note__starts-before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .calendar__note__ends-after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .hidden {
    visibility: hidden;
  }
