
  .accordion__header {
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .accordion__icon {
    font-size: 25px;
  }
