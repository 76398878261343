
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/link";
  @use "frontend/styles/mixins/tiles";
  @use "frontend/styles/mixins/label";

  .popup-appointment {
    background-color: colors.$color_popup--background;
    padding: 20px;

    h5 {
      margin-top: 0;
    }
  }

  .popup-appointment__errors {
    margin-top: 25px;
  }

  .popup-appointment__error {
    @include label.label;
    @include label.label--error;
  }

  .popup-appointment__actions {
    display: flex;
    justify-content: center;

    margin-top: 30px;
  }

  .popup-appointment__action {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .popup-appointment__labeling {
    margin: 25px 0;
  }

  .popup-appointment__decisions {
    @include tiles.tile__collection;
    justify-content: center;

    margin: 5px 0;
  }

  .popup-appointment__decision {
    @include tiles.tile;

    padding: 7px 15px;
    border-radius: 3px;

    font-weight: bold;
    font-size: 12px;

    &:hover {
      @include tiles.tile--active;
    }
  }

  .popup-appointment__decision--active {
    @include tiles.tile--active;
  }

  .popup-appointment__row-units {
    display: flex;
    flex-direction: row;
  }

  .popup-appointment__column-units {
    display: flex;
    flex-direction: column;
  }

  .popup-appointment__row-unit {
    max-width: 220px;
    margin-top: 10px;
    padding-right: 10px;
    padding-left: 10px;

    border-left: 1px solid colors.$color_settings-sessions--border;

    &:first-of-type {
      border-left: none;
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }

  .popup-appointment__column-unit {
    margin-top: 30px;
  }

  .popup-appointment__unit__deletion-link {
    color: colors.$color_link--text;
    font-size: 12px;
    cursor: pointer;
    text-align: end;
  }

  .popup-appointment__unit__add {
    @include link.link;

    margin-top: 10px;
    font-size: 12px;
    text-align: end;
  }

  .popup-appointment__modal-actions {
    padding-bottom: 20px;
  }
