
  @use "shared/styles/colors";
  @use "frontend/styles/dimensions";

  .modal__actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal__action {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .modal__close {
    position: absolute;

    right: 0;
    top: 0;

    border-radius: 0;
    border-top-right-radius: dimensions.$dimension_modal--border-radius;
  }
