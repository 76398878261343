
  @use "shared/styles/colors";

  .warning {
    color: colors.$color_confirm_absence__warning--text;

    margin-bottom: 5px;
    font-size: 13px;
  }

  .actions {
    margin-top: 25px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
