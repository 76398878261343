
  @use "frontend/styles/mixins/link";

  .popup-appointment__search-patient-link {
    @include link.link;

    margin-left: 10px;
  }

  .popup-appointment__element {
    margin-bottom: 10px;
  }
