
  @use "shared/styles/colors";

  .base-resize-bar {
    height: 1px;
    width: 100%;

    cursor: row-resize;

    &:hover {
      border: 1px solid colors.$color_base-resize-bar--separator;
    }
  }

  .base-resize-bar--resizing {
    &,
    &:hover {
      border: 1px solid colors.$color_base-resize-bar--separator--resizing;
    }
  }
