@use "shared/styles/colors";

@mixin tile() {
  user-select: none;
  cursor: pointer;
}

@mixin tile--active() {
  background-color: colors.$color_tile--hover--background;
  color: colors.$color_tile--hover--text;
}

@mixin tile__collection() {
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
