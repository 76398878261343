
  @use "sass:color";
  @use "shared/styles/colors";
  @use "frontend/styles/dimensions";

  .edit-pvs-configuration {
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    padding: 20px;
    background-color: #f1f1f1;
  }

  .button {
    padding: 7px 20px;
    color: colors.$color_button--text;
    background-color: colors.$color_button--background;
    border: none;
    border-radius: dimensions.$dimension_button--border-radius;
    font-weight: bold;
    text-decoration: none;
    font-size: 12px;

    margin-right: 10px;

    &:hover {
      background-color: color.adjust(
        colors.$color_button--background,
        $lightness: -5%
      );
    }
  }

  .headline {
    margin: 0;
  }

  .configuration__form {
    margin: 30px 0;
  }

  .configuration__form-row {
    display: flex;
    flex-flow: row wrap;
  }

  .configuration__form-label {
    width: 200px;
    align-self: center;
  }

  .configuration__form-input {
    flex: 1;
  }

  .buttons {
    text-align: center;
    margin: 30px 0;
  }

  ul {
    list-style: none;
    padding-left: 10px;
  }

  .configuration__active-installations {
    color: #0000ff;
    margin-top: 10px;
    font-size: 14px;
  }

  .red {
    color: #ff0000;
  }
