
  @use "shared/styles/colors";
  @use "sass:color";

  .calendar__cell {
    height: 100%;

    // positioning inside
    position: relative;

    // NOTE: If you change the width of the border,
    //       see use-day-view-parser.ts to adjust
    //       heightPixels in attendances too
    /* stylelint-disable-next-line order/order */ // TODO: Fix this
    $_border-definition: 1px solid colors.$color_calendar--cell-border;
    border-left: $_border-definition;
    border-bottom: $_border-definition;
  }
  .calendar__slot {
    height: 100px;
  }

  .calendar__entry {
    position: absolute;
    left: 0;
    right: 0;
  }

  .calendar__entry__mock {
    padding: 5px;
    font-size: 12px;
    color: colors.$color_text--global;
  }

  .calendar__entry__bc-component {
    height: 100%;
  }
