
  @use "shared/styles/colors";
  @use "frontend/styles/features";

  .unit-absence {
    // STRIPES
    $_stripes-width: 6px;
    background: repeating-linear-gradient(
      -45deg,
      colors.$color_calendar__unit__diagonal-stripes--c2,
      colors.$color_calendar__unit__diagonal-stripes--c2 #{$_stripes-width},
      colors.$color_calendar__unit__diagonal-stripes--c1 #{$_stripes-width},
      colors.$color_calendar__unit__diagonal-stripes--c1 #{2 * $_stripes-width}
    );

    padding: 5px;
  }

  .unit-absence__type-label {
    text-align: center;
  }
