
  .vue-route__not-found {
    text-align: center;
  }
  .error-code {
    margin-top: 20px;
    font-size: 75px;
    text-align: center;

    font-family: monospace;
  }
