
  @use "shared/styles/colors";

  .page-navigation {
    display: flex;
    justify-content: space-between;
  }

  .page-navigation__heading {
    font-weight: bold;
    font-size: 12px;
  }

  .page-navigation__entry {
    font-size: 12px;
  }

  .page-navigation__link {
    color: colors.$color_link--text;

    cursor: pointer;
  }

  .page-navigation__link--active {
    font-weight: bold;
  }
