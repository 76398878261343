
  @use "sass:color";
  @use "shared/styles/colors";
  @use "frontend/styles/dimensions";
  @use "frontend/styles/features";

  .button {
    // positioning outer
    display: inline-block;

    // size and spacing
    padding: 7px 20px;
    line-height: 1;

    // visual style
    font-family: "Open Sans Condensed", sans-serif;
    background-color: colors.$color_button--background;
    color: colors.$color_button--text;
    border: none;
    border-radius: dimensions.$dimension_button--border-radius;

    @if features.$feature_button--uppercase {
      text-transform: uppercase;
    }
    font-weight: bold;
    text-decoration: none;
    font-size: 17px;

    // functional style
    cursor: pointer;

    &:hover {
      background-color: color.adjust(
        colors.$color_button--background,
        $lightness: -5%
      );
    }
  }

  .button--action {
    // visual style
    background-color: colors.$color_button__action--background;
    color: colors.$color_button__action--text;

    &:hover {
      background-color: color.adjust(
        colors.$color_button__action--background,
        $lightness: -5%
      );
    }
  }

  .button--warn {
    background-color: colors.$color_button__warn--background;
    color: colors.$color_button__warn--text;

    &:hover {
      background-color: color.adjust(
        colors.$color_button__warn--background,
        $lightness: -5%
      );
    }
  }

  .button--inverted {
    // visual style
    color: colors.$color_button__inverted--text;
    background-color: colors.$color_button__inverted--background;

    &:hover {
      background-color: color.adjust(
        colors.$color_button__inverted--background,
        $lightness: -5%
      );
    }
  }

  .button--inverted.button--action {
    // visual style
    background-color: colors.$color_button__action--text;
    color: colors.$color_button__action--background;

    &:hover {
      background-color: color.adjust(
        colors.$color_button__action--text,
        $lightness: -5%
      );
    }
  }

  .button--inverted.button--warn {
    background-color: colors.$color_button__warn--text;
    color: colors.$color_button__warn--background;

    &:hover {
      background-color: color.adjust(
        colors.$color_button__warn--text,
        $lightness: -5%
      );
    }
  }

  .button--full-width {
    display: block;
    width: 100%;
    text-align: center;
  }

  .button--disabled,
  .button--loading {
    cursor: not-allowed;
  }

  .button--small {
    font-size: 12px;
  }
