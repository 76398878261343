
  @use "shared/styles/colors";

  .patient-profile {
    margin: 20px 0;
    background-color: colors.$color_patient-search__patient--background;
    padding: 10px;
  }

  .patient-name {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .patient-info {
    margin: 5px 0;
  }
