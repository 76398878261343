
  @use "shared/styles/colors";
  @use "frontend/styles/dimensions";
  @use "frontend/styles/features";

  .box {
    // positioning inside
    padding: 20px;

    // visual style
    background-color: colors.$color_box--background;
    color: colors.$color_box--text;
    font-size: 14px;
    border-radius: dimensions.$dimension_box--border-radius;
  }

  .box__heading {
    // positioning outside
    margin-bottom: 10px;

    // positioning inside
    text-align: center;
    padding-bottom: 15px;
    padding-top: 15px;

    // visual style
    color: colors.$color_box__heading--text;

    @if features.$feature_heading--uppercase {
      text-transform: uppercase;
    }
    font-weight: bold;
    font-size: 19px;
    border-bottom: 1px solid colors.$color_box__heading--separator;
  }

  .box--bottom-spacing {
    margin-bottom: 15px;
  }

  .box--light {
    background-color: colors.$color_box__light--background;
  }
