
  @use "shared/styles/colors";

  .accordion__bar {
    cursor: pointer;
    user-select: none;
    padding: 10px 0;

    display: flex;
    justify-content: space-between;

    border-top: 1px solid colors.$color_filter-container__accordion--border;
    border-bottom: 1px solid colors.$color_filter-container__accordion--border;
  }

  .filter-icon {
    font-size: 16px;
    margin-right: 5px;
  }

  .accordion__bar__label {
    font-size: 12px;
    font-weight: bold;
    color: colors.$color_filter-container__accordion--label;
  }

  .accordion__bar__icon {
    color: colors.$color_filter-container__accordion--label;
  }

  .accordion__content {
    border-bottom: 1px solid colors.$color_filter-container__accordion--border;
    padding: 20px 0;
  }
