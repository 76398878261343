
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/label";
  @use "frontend/styles/mixins/link";

  .padded-box {
    padding: 10px 0;
  }

  .navigation-button__container {
    padding: 15px 0;
    width: 190px;
    margin: 0 auto; // center horizontally

    text-align: center;
  }

  .patient-create-appointment__no-suggestions {
    @include label.label;
    @include label.label--error;
  }

  .patient-create-appointment__description {
    ::v-deep(a) {
      @include link.link;
    }
  }

  .spinner__container {
    display: flex;
    justify-content: center;

    margin-top: 15px;
    margin-bottom: 15px;
  }

  .spinner {
    width: 20px;
  }

  .patient-create-appointment__reservation {
    @include label.label;

    margin-top: 15px;
  }

  .patient-create-appointment__reservation--expired {
    @include label.label--error;
  }
