
  .vue-component__wizard-steps {
    $_label-overlap: 30px;
    .steps {
      display: flex;
      justify-content: space-between;

      padding-bottom: 25px;
      padding-left: $_label-overlap;
      padding-right: $_label-overlap;
    }

    .steps__step {
      flex: 1;

      &:first-of-type,
      &:last-of-type {
        flex: 0.5;
      }
    }

    .step {
      display: flex;
      align-items: center;

      position: relative;
    }

    .step__label {
      font-size: 12px;
      position: absolute;
      text-align: center;
      bottom: -20px;
      left: -$_label-overlap;
      right: -$_label-overlap;
    }

    .step__icon--disabled {
      &,
      &:hover {
        cursor: not-allowed;
        .step__label {
          font-weight: initial;
        }
      }
    }

    .step__icon {
      $_font-size: 20px;
      font-size: $_font-size;

      display: flex;
      align-items: center;

      position: relative;

      &:hover {
        cursor: pointer;

        .step__label {
          font-weight: bold;
        }
      }
    }

    .step__line {
      flex: 1;
    }

    .step__line--invisible {
      display: none;
    }

    .step--disabled {
      cursor: not-allowed;
    }

    .content {
      margin-top: 15px;
    }
  }
