
  @use "shared/styles/colors";

  .vue-route__login--index {
    display: flex;
    justify-content: center; // horizontally
    align-items: center; // vertically
  }

  .login-form {
    max-width: 400px;
  }

  .login-form__inputs {
    margin: 20px 0;
  }

  .login-form__errors {
    color: colors.$color_login-form__errors--text;
    font-size: 14px;
  }

  .login-form__hidden-submit {
    display: none;
  }
