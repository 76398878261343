
  @use "shared/styles/colors";
  @use "frontend/styles/mixins/label";

  .form-select {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
  }

  .form-select__label,
  .form-select__label__error {
    @include label.label;
  }

  .form-select__label__error {
    @include label.label--error;
  }

  .form-select__select {
    background-color: colors.$color_form-select--background;
    border: none;
    padding: 10px;
    border-radius: 3px;
    color: colors.$color_form-select--text;
    font-weight: bold;
    font-size: 16px;
  }

  .form-select__text {
    background-color: colors.$color_form-select__chooser--background;
    color: colors.$color_form-select__chooser--text;
    border: none;
    border-bottom: 1px solid colors.$color_form-select--text;
  }

  .form-select__option {
    background-color: colors.$color_form-select__option--background;
    border: none;
    padding: 13px;
  }

  // ANIMATION-STATUS:
  .form-select__status-container {
    display: flex;
    align-items: center;
  }

  .form-select__animation-status {
    margin-left: 10px;
    margin-top: 3px;
  }

  /* stylelint-disable-next-line no-duplicate-selectors */
  .form-select__select {
    flex: 1;
    max-width: 100%;
  }

  .form-select--small {
    .form-select__select {
      font-size: 12px;
      padding: 5px;
    }
  }

  .form-select--inverted {
    .form-select__select,
    .form-select__option {
      color: colors.$color_form-select--background;
      background-color: colors.$color_form-select--text;
    }
  }

  .form-select--no-label {
    padding: 0;
  }
