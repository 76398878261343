
  @use "shared/styles/colors";

  .vue-component__style-guide {
    .backgrounded {
      background-color: colors.$color_main-area--background;
      color: colors.$color_main-area--text;

      padding: 20px;
      margin: 20px 0;
    }
  }
