
  @use "shared/styles/colors";

  .operator-selector {
    cursor: pointer;
  }

  .operator-selector__popup-container {
    padding: 10px;
    background-color: colors.$color_operator-selector_popup--background;
  }

  .operator-selector__tag {
    display: inline-block;
    min-width: 60px;
    margin-bottom: 3px;
  }

  .operator-selector__option {
    padding: 5px;

    &:hover,
    &.operator-selector__option--active {
      background-color: colors.$color_operator-selector--hover;
    }
  }

  .operator-select__description {
    font-size: 11px;
    padding: 10px;
  }

  .operator-selecto__no-selection {
    display: inline-block;

    font-size: 20px;
    color: colors.$color_operator-tag--no-selection;

    margin-top: 12px;
    margin-left: 5px;
  }
