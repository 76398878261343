
  @use "frontend/styles/mixins/label";

  .form-checkbox {
    display: flex;
    align-items: center;

    margin: 5px 0;
  }

  .form-checkbox__label,
  .form-checkbox__label__error,
  .form-checkbox__slot {
    @include label.label;

    padding: 0;
  }

  .form-checkbox__label__error {
    @include label.label--error;
  }

  .form-checkbox__label,
  .form-checkbox__label__error,
  .form-checkbox__animation-status,
  .form-checkbox__slot {
    margin-left: 5px;
  }
