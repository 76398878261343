
  @use "shared/styles/colors";
  @use "frontend/styles/dimensions";
  @use "frontend/styles/variables";

  .vue-route__index {
    display: flex;
    flex-direction: column;

    min-height: 100vh;
  }

  .main {
    display: flex;
    flex-direction: column;
    flex: 1;

    padding: dimensions.$dimension_main--padding-tb
      dimensions.$dimension_main--padding-lr;

    // visual style
    background-color: colors.$color_main-area--background;
    color: colors.$color_main-area--text;
  }

  .main--skip-padding {
    padding: 0;
  }
