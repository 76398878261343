
  @use "shared/styles/colors";

  .main-heading {
    text-transform: none;
    margin: 10px 0;
  }

  .link {
    padding: 10px 0;

    &,
    a,
    a:visited {
      color: colors.$color_link--text;

      font-size: 14px;
    }
  }
