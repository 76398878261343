@mixin draggable() {
  cursor: grab;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -18px;
    width: 15.2px;
    height: 11px;
    background-image: url("shared/assets/icons/material/drag.svg");
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover::before {
    opacity: 1;
  }
}
