
  @use "frontend/styles/mixins/label";

  .form-office-color__label-and-status {
    display: flex;
  }

  .form-office-color__label {
    @include label.label;

    display: block;
  }

  .form-office-color__container {
    display: flex;

    align-items: center;

    margin-top: 5px;
  }

  .form-office-color__preview {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;
    margin-right: 15px;

    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .form-office-color__preview-icon--selected {
    font-size: 20px;
  }
