
  @use "shared/styles/colors";

  .show-unit__units {
    .show-unit__unit {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .show-unit__appointment-container {
    display: flex;
  }

  .show-unit__appointment-column {
    margin-right: 25px;

    flex: 1;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .show-unit__heading--no-lower-distance {
    margin-bottom: 0;
  }

  .edit-action,
  .choose-color {
    color: colors.$color_edit-action;
    cursor: pointer;
  }

  .edit-action {
    margin-left: 5px;
  }

  .choose-color {
    font-size: 12px;
    margin-top: 10px;
  }

  .name-container,
  .choose-color {
    display: flex;
    align-items: center;
  }

  .name-text {
    flex: 1;
  }
