
  @use "sass:color";
  @use "shared/styles/colors";
  @use "frontend/styles/dimensions";

  .pillar-navigation {
    font-size: 25px;

    cursor: pointer;

    display: flex;
    align-items: center;
  }

  .pillar-calendar {
    display: grid;
    grid-auto-flow: column;
  }

  .pillar-calendar__cell {
    padding: 5px;

    text-align: center;
  }

  .pillar-calendar__droplet {
    display: inline-block;

    font-weight: bold;

    padding: 3px 8px;
    border-radius: dimensions.$dimension_pillar-calendar--droplet-radius;

    background-color: colors.$color_pillar-calendar__droplet--background;
    color: colors.$color_pillar-calendar__droplet--text;

    cursor: pointer;

    &:hover {
      background-color: color.scale(
        colors.$color_pillar-calendar__droplet--background,
        $lightness: -15%
      );
    }
  }

  .pilar-calendar__empty-droplet {
    display: inline-block;
    width: 20px;
    height: 3px;
    background-color: colors.$color_pillar-calendar__droplet-empty--background;
  }

  .pillar-calendar__header {
    border-bottom: 1px solid colors.$color_pillar-calendar__heading-separator;
  }

  .pillar-calendar__title,
  .pillar-calendar__sub-title {
    font-size: 14px;
    line-height: 1.3;
  }
  .pillar-calendar__title {
    font-weight: bold;
  }

  .pillar-calendar__droplet--first {
    margin-top: 15px;
  }

  .pillar-calendar__cell--selected {
    font-weight: bold;
    color: colors.$color_pillar-calendar__droplet--background;
  }
