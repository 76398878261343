
  @use "shared/styles/colors";

  .aptype__container {
    display: flex;
    flex-wrap: wrap;
  }

  .aptype {
    padding: 20px 30px;
    border: 1px solid colors.$color_create-appointment__box--border;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: colors.$color_create-appointment__box--background;
    width: 100%;

    cursor: pointer;

    &:hover,
    &.aptype--selected {
      background-color: colors.$color_create-appointment__box-hover-selection--background;
      color: colors.$color_create-appointment__box-hover-selection--text;
    }
  }
  .aptype__info {
    font-size: 14px;
  }

  .aptype__info--irregularity {
    color: colors.$color_create_appointment__box__warning--text;
  }

  .calendars__container {
    display: flex;
  }

  .calendars__mini {
    margin-right: 20px;
  }

  .calendars__big {
    flex: 1 0;
  }

  .back-to-settings {
    cursor: pointer;
    font-size: 12px;
    margin-left: 20px;
    font-style: italic;
  }
  .termin-info {
    margin: 5px 0;
  }

  .topbar__attendance-view {
    text-align: right;
    margin: 20px 0;
  }

  .appointment-suggestion__date {
    font-size: 14px;
    margin-top: 30px;
    font-weight: bold;
  }
  .appointment-suggestion__time {
    display: inline;
    color: colors.$color_header--text;
    font-weight: bold;
  }

  .appointment-suggestion__unit,
  .appointment-suggestion__order-time {
    margin-right: 8px;
  }
