
  @use "shared/styles/colors";

  .popup-appointment__unit-duration {
    margin-top: 5px;

    user-select: none;

    display: flex;
    justify-content: center;
  }

  .popup-appointment__unit-duration__button-minus {
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .popup-appointment__unit-duration__button-plus {
    margin-left: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .popup-appointment__header {
    min-width: 180px;
  }

  .popup-appointment__header__content {
    margin-left: 8px;
  }

  :deep(.form-input__input) {
    border: 1px solid colors.$color_input--border;
    font-size: 12px;
  }

  :deep(.vue-component__form-input) {
    padding: 0;
  }

  :deep(.form-input__label) {
    padding: 0;
  }

  :deep(.form-select__select) {
    width: 200px;
  }
