
  @use "shared/styles/colors";

  .appointment-box__container {
    background-color: colors.$color_main-area--background;
    color: colors.$color_create-appointment__box-new--text;
    padding: 10px;
    display: flex;
  }

  .appointment-icon__calendar {
    font-size: 20px;
    padding-right: 10px;
  }

  .appointment__date {
    margin-bottom: 5px;
  }
